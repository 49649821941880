import { defineNuxtPlugin, useRuntimeConfig } from 'nuxt/app';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  // Pusher.logToConsole = true;

  (window as any).Pusher = Pusher;

  const { API_URL, pusherKey, pusherCluster } = config.public;
  const hostname = window.location.hostname.split('.')[0];
  const apiUrl = `https://${hostname}.${API_URL}`;

  const echo = new Echo({
    broadcaster: 'pusher',
    key: pusherKey,
    cluster: pusherCluster,
    forceTLS: true,
    authorizer: (channel: any) => {
      return {
        authorize: (
          socketId: string,
          callback: (status: boolean, data: any) => void,
        ) => {
          const token = localStorage.getItem('token');

          fetch(`${apiUrl}/broadcasting/auth`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token ? `Bearer ${token}` : '',
            },
            body: JSON.stringify({
              socket_id: socketId,
              channel_name: channel.name,
            }),
          })
            .then(async (response) => {
              if (!response.ok) {
                throw new Error('Authorization failed');
              }
              const data = await response.json();
              callback(false, data);
            })
            .catch((error) => {
              console.error('Pusher authorization error:', error);
              callback(true, error);
            });
        },
      };
    },
  });

  return {
    provide: {
      echo,
    },
  };
});

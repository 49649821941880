export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('click-outside', {
    beforeMount(
      el: HTMLElement & { clickOutsideEvent?: (event: Event) => void },
      binding: { value: (event: Event) => void },
    ) {
      el.clickOutsideEvent = (event: Event) => {
        if (!(el === event.target || el.contains(event.target as Node))) {
          if (typeof binding.value === 'function') {
            binding.value(event);
          }
        }
      };
      document.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(
      el: HTMLElement & { clickOutsideEvent?: (event: Event) => void },
    ) {
      if (el.clickOutsideEvent) {
        document.removeEventListener('click', el.clickOutsideEvent);
      }
    },
  });
});

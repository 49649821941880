import { default as indexIF60BI3ZAoMeta } from "/var/www/insylio_beta/front/pages/calendar/index.vue?macro=true";
import { default as indexdgJ4AVCR7PMeta } from "/var/www/insylio_beta/front/pages/calendar/month/index.vue?macro=true";
import { default as indexnwdo7L9pDHMeta } from "/var/www/insylio_beta/front/pages/calendar/today/index.vue?macro=true";
import { default as indexJWYe43LmDDMeta } from "/var/www/insylio_beta/front/pages/calendar/week/index.vue?macro=true";
import { default as _91hash_93LQrYBkjLTgMeta } from "/var/www/insylio_beta/front/pages/chat/[hash].vue?macro=true";
import { default as chat71SGfKy0QFMeta } from "/var/www/insylio_beta/front/pages/chat.vue?macro=true";
import { default as indexvxxfCnu7OLMeta } from "/var/www/insylio_beta/front/pages/employees/index.vue?macro=true";
import { default as indexRUUpvT3kuFMeta } from "/var/www/insylio_beta/front/pages/forms/index.vue?macro=true";
import { default as indexeLb8PC5KW1Meta } from "/var/www/insylio_beta/front/pages/index.vue?macro=true";
import { default as indexggeQstTry6Meta } from "/var/www/insylio_beta/front/pages/login/index.vue?macro=true";
import { default as indexzsoNp4xQs6Meta } from "/var/www/insylio_beta/front/pages/medicines/index.vue?macro=true";
import { default as index3n1r2NP8JEMeta } from "/var/www/insylio_beta/front/pages/notifications/index.vue?macro=true";
import { default as indexvCH7QaxUKlMeta } from "/var/www/insylio_beta/front/pages/patients/index.vue?macro=true";
import { default as indexSkAo28qG8WMeta } from "/var/www/insylio_beta/front/pages/register/index.vue?macro=true";
import { default as indexb90tz5iNY5Meta } from "/var/www/insylio_beta/front/pages/remind-password/index.vue?macro=true";
import { default as indexSVlyGOTulWMeta } from "/var/www/insylio_beta/front/pages/reports/costs-estimate/index.vue?macro=true";
import { default as indexa4yKdooP82Meta } from "/var/www/insylio_beta/front/pages/reports/index.vue?macro=true";
import { default as indexRJwVfUkwocMeta } from "/var/www/insylio_beta/front/pages/reports/other-reports/index.vue?macro=true";
import { default as indexGL1v9PAnzIMeta } from "/var/www/insylio_beta/front/pages/reset-password/index.vue?macro=true";
import { default as indexQwRajbMpECMeta } from "/var/www/insylio_beta/front/pages/schemats/index.vue?macro=true";
import { default as index8rMBL0ew7qMeta } from "/var/www/insylio_beta/front/pages/surveys/index.vue?macro=true";
import { default as indexncXWeHlkvrMeta } from "/var/www/insylio_beta/front/pages/symptoms/index.vue?macro=true";
import { default as indexobBmd2GHnOMeta } from "/var/www/insylio_beta/front/pages/verify-code/index.vue?macro=true";
export default [
  {
    name: "calendar",
    path: "/calendar",
    meta: indexIF60BI3ZAoMeta || {},
    component: () => import("/var/www/insylio_beta/front/pages/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: "calendar-month",
    path: "/calendar/month",
    meta: indexdgJ4AVCR7PMeta || {},
    component: () => import("/var/www/insylio_beta/front/pages/calendar/month/index.vue").then(m => m.default || m)
  },
  {
    name: "calendar-today",
    path: "/calendar/today",
    meta: indexnwdo7L9pDHMeta || {},
    component: () => import("/var/www/insylio_beta/front/pages/calendar/today/index.vue").then(m => m.default || m)
  },
  {
    name: "calendar-week",
    path: "/calendar/week",
    meta: indexJWYe43LmDDMeta || {},
    component: () => import("/var/www/insylio_beta/front/pages/calendar/week/index.vue").then(m => m.default || m)
  },
  {
    name: "chat",
    path: "/chat",
    meta: chat71SGfKy0QFMeta || {},
    component: () => import("/var/www/insylio_beta/front/pages/chat.vue").then(m => m.default || m),
    children: [
  {
    name: "chat-hash",
    path: ":hash()",
    component: () => import("/var/www/insylio_beta/front/pages/chat/[hash].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "employees",
    path: "/employees",
    meta: indexvxxfCnu7OLMeta || {},
    component: () => import("/var/www/insylio_beta/front/pages/employees/index.vue").then(m => m.default || m)
  },
  {
    name: "forms",
    path: "/forms",
    meta: indexRUUpvT3kuFMeta || {},
    component: () => import("/var/www/insylio_beta/front/pages/forms/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexeLb8PC5KW1Meta || {},
    component: () => import("/var/www/insylio_beta/front/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: indexggeQstTry6Meta || {},
    component: () => import("/var/www/insylio_beta/front/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "medicines",
    path: "/medicines",
    meta: indexzsoNp4xQs6Meta || {},
    component: () => import("/var/www/insylio_beta/front/pages/medicines/index.vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: index3n1r2NP8JEMeta || {},
    component: () => import("/var/www/insylio_beta/front/pages/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "patients",
    path: "/patients",
    meta: indexvCH7QaxUKlMeta || {},
    component: () => import("/var/www/insylio_beta/front/pages/patients/index.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: indexSkAo28qG8WMeta || {},
    component: () => import("/var/www/insylio_beta/front/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: "remind-password",
    path: "/remind-password",
    meta: indexb90tz5iNY5Meta || {},
    component: () => import("/var/www/insylio_beta/front/pages/remind-password/index.vue").then(m => m.default || m)
  },
  {
    name: "reports-costs-estimate",
    path: "/reports/costs-estimate",
    meta: indexSVlyGOTulWMeta || {},
    component: () => import("/var/www/insylio_beta/front/pages/reports/costs-estimate/index.vue").then(m => m.default || m)
  },
  {
    name: "reports",
    path: "/reports",
    meta: indexa4yKdooP82Meta || {},
    component: () => import("/var/www/insylio_beta/front/pages/reports/index.vue").then(m => m.default || m)
  },
  {
    name: "reports-other-reports",
    path: "/reports/other-reports",
    meta: indexRJwVfUkwocMeta || {},
    component: () => import("/var/www/insylio_beta/front/pages/reports/other-reports/index.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: indexGL1v9PAnzIMeta || {},
    component: () => import("/var/www/insylio_beta/front/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "schemats",
    path: "/schemats",
    meta: indexQwRajbMpECMeta || {},
    component: () => import("/var/www/insylio_beta/front/pages/schemats/index.vue").then(m => m.default || m)
  },
  {
    name: "surveys",
    path: "/surveys",
    meta: index8rMBL0ew7qMeta || {},
    component: () => import("/var/www/insylio_beta/front/pages/surveys/index.vue").then(m => m.default || m)
  },
  {
    name: "symptoms",
    path: "/symptoms",
    meta: indexncXWeHlkvrMeta || {},
    component: () => import("/var/www/insylio_beta/front/pages/symptoms/index.vue").then(m => m.default || m)
  },
  {
    name: "verify-code",
    path: "/verify-code",
    meta: indexobBmd2GHnOMeta || {},
    component: () => import("/var/www/insylio_beta/front/pages/verify-code/index.vue").then(m => m.default || m)
  }
]